<template>
    <div class="section-page">
        <div class="container section-page__container">
            <aside class="section-page__sidebar">
                <div class="section-page__sidebar-inner">
                    <nav class="section-page__nav section-nav section-nav_layout_column">
                        <ul class="section-nav__list">
                            <li class="section-nav__item" v-for="(airplaneItem, index) of airplanes" :key="index">
                                <router-link
                                    exact-active-class="section-nav__link_active"
                                    :to="{
                                        name: 'Airplane',
                                        params: { lang: $i18n.locale, airplaneName: airplaneItem.img },
                                    }"
                                    class="section-nav__link link"
                                    >{{ airplaneItem.title }}</router-link
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
            <transition name="fade">
                <div class="section-page__content airplane-detail" v-show="fadeAirplaneInfo">
                    <template v-if="airplane">
                        <h1 class="airplane-detail__title section-title">{{ airplane.title }}</h1>
                        <p class="airplane-detail__description">{{ airplane.description['ru'] }}</p>
                        <Stats class="airplane-detail__stats stats_expand" :stats-list="airplane.statsList" />
                        <div class="airplane-detail__slider detail-slider">
                            <VueSlickCarousel v-bind="carouselSettings" ref="detailSlider">
                                <div class="detail-slider__slide" v-for="(detailImage, index) of Array(5)" :key="index">
                                    <h3 class="detail-slider__title">{{ slidesTitle[index]['ru'] }}</h3>
                                    <div class="detail-slider__img-wrapper">
                                        <img
                                            class="detail-slider__img"
                                            :src="
                                                require(`@/assets/images/catalog/${airplane.img}/details/0${index +
                                                    1}.jpg`)
                                            "
                                            :alt="slidesTitle[index]['ru']"
                                        />
                                    </div>
                                </div>
                                <template #customPaging>
                                    <button class="detail-slider__dot" />
                                </template>
                            </VueSlickCarousel>
                        </div>
                        <template v-if="airplane.news">
                            <div
                                class="airplane-detail__message message"
                                :class="{
                                    'airplane-detail__message_wide message_offset message_theme_accent': index % 2,
                                }"
                                v-for="(newItem, index) of airplane.news"
                                :key="index"
                            >
                                <h3 class="message__title">{{ newItem.title['ru'] }}</h3>
                                <p class="message__description">
                                    {{ newItem.description['ru'] }}
                                </p>
                            </div>
                        </template>
                        <div v-if="airplane.link" class="airplane-detail__message message">
                            <h3 class="message__title" v-html="airplane.link.title['ru']" />
                            <div class="message__link">
                                <a :href="airplane.link.href" target="_blank" class="message__link large-link">
                                    {{ airplane.link.text }}
                                    <AppIcon class="large-link__icon" name="arrow-right" width="57" height="17" />
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h1 class="airplane-detail__title section-title">{{ $t('airplane-page.404') }}</h1>
                    </template>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Stats from '@/components/Stats'
import VueSlickCarousel from 'vue-slick-carousel'

const airplaneData = require('./airplanes.json')

export default {
    name: 'Airplane',
    components: {
        Stats,
        VueSlickCarousel,
    },
    data() {
        return {
            fadeAirplaneInfo: true,
            airplane: null,
            carouselSettings: {
                arrows: false,
                touchMove: false,
                speed: 1300,
                fade: true,
                dots: true,
                dotsClass: 'detail-slider__dots',
                autoplay: true,
                pauseOnDotsHover: true,
                pauseOnHover: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            touchMove: true,
                            speed: 700,
                        },
                    },
                ],
            },
        }
    },
    computed: {
        airplanes() {
            return airplaneData
        },
        slidesTitle() {
            return [
                {
                    ru: 'Внешний вид самолёта',
                    en: 'Внешний вид самолёта',
                },
                {
                    ru: 'Интерьер самолёта',
                    en: 'Интерьер самолёта',
                },
                {
                    ru: 'самолёт на площадке аэропорта',
                    en: 'самолёт на площадке аэропорта',
                },
                {
                    ru: 'Интерьер самолёта',
                    en: 'Интерьер самолёта',
                },
                {
                    ru: 'Дополнительно фото самолёта',
                    en: 'Дополнительно фото самолёта',
                },
            ]
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler: function(to) {
                this.fadeAirplaneInfo = false
                setTimeout(() => {
                    this.fadeAirplaneInfo = true
                    this.airplane = airplaneData.find((airplane) => airplane.img === to.params.airplaneName)
                }, 500)
            },
        },
    },
    created() {},
    methods: {},
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 500ms;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
